:root {
  --first-drop: 900px;
  --over-width: 4096px;
  --color-blue-border-text: #3347f2;
  --general-style-color: #101634;
  --block-shadow: 0px 0px 25px 0px var(--color-blue-border-text);
}

body {
  background-color: var(--general-style-color);
  overflow-x: hidden;
}

.container {
  width: 1280px;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.loading {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  z-index: 15;
  transition: 1s ease;
}

.hiddenLoading {
  height: 0;
  transform: translateY(-70px);
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.w100 {
  width: 100%;
}

.mtb60 {
  margin: 70px auto;
}

.blockHeaderText {
  font-family: "Open Sans";
  font-size: 24px;
  color: #fff;
  margin: 150px 0 40px 0;
  scroll-margin: 40px;
}

.burger {
  display: none;
}

.generalBlock {
  width: var(--first-drop);
  font-family: "Open Sans";
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.header {
  width: 100%;
  height: 10vh;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu {
  width: var(--first-drop);
  height: 42px;
  margin: 0 auto;
  border-radius: 10px;
  background-color: rgba(100, 112, 220, 0.1);
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
}

.bgcNone {
  background-color: transparent;
}

.menuButton {
  color: rgba(255, 255, 255, 0.7);
  font-family: "Open Sans";
  height: 22px;
  padding: 0;
  border: none;
  background: none;
  font-size: 16px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.menuButton::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0087ca;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.menuButton:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.nameBlock {
  font-family: "Ubuntu";
  height: 90vh;
}

.internalNameBlock {
  width: 100%;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.sePosition {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 48%;
  color: var(--general-style-color);
  font-size: 60px;
  font-weight: 700;
  line-height: 69px;
  letter-spacing: 0em;
  text-align: left;
  align-self: flex-end;
}

.fullName {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 48%;
  flex-wrap: wrap;
  color: #fff;
}
.firstName {
  width: 100%;
  font-size: 60px;
  font-weight: 700;
  line-height: 69px;
  letter-spacing: 0em;
  text-align: right;
}
.lastName {
  width: 100%;
  font-size: 85px;
  font-weight: 500;
  line-height: 98px;
  letter-spacing: 0em;
  text-align: right;
}

.descriptionBlock {
  width: var(--first-drop);
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  scroll-margin: 40px;
}

.fullWidthBlock {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.professionalSkillsLine {
  height: 100px;
  color: #fff;
  background-color: var(--general-style-color);
  font-family: "Open Sans";
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0.03em;
  margin: 150px 0 50px 0;
}

.skillsBlock {
  width: var(--first-drop);
  font-family: "Ubuntu";
}

.educationItemsContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  color: #fff;
}
.educationItem {
  width: 40%;
  padding: 10px;
  height: 220px;
  display: flex;
  background-color: var(--general-style-color);
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  border: 1px solid white;
  border-radius: 20px;
  box-shadow: var(--block-shadow);
  margin: 10px 0;
}
.educationLine {
  display: flex;
  justify-content: center;
  align-items: start;
  flex: 0 1 30%;
}
.textColorBlue {
  color: var(--color-blue-border-text);
}

.customSlide {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 40px;
  font-family: "Open Sans";
  color: #fff;
  height: 300px;
  border: 1px solid white;
  border-radius: 20px;
  background-color: var(--general-style-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
}

.slideRow {
  flex: 0 1 20%;
  display: flex;
  align-items: center;
}

.slideTitle {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
}
.slideDescription {
  font-size: 20px;
  text-align: start;
}
.slideButtons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.customButton {
  display: flex;
  color: #3347f2;
  background-color: var(--general-style-color);
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  font-family: "Open Sans";
  padding: 5px 25px;
  align-items: flex-start;
  gap: 10px;
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--accent, #3347f2);
  width: fit-content;
  transition: 0.5s ease;
}
.customButton:disabled {
  opacity: 0.7;
}
.customButton:hover {
  transform: scale(1.05);
}
.buttonIcon {
  font-size: 22px;
}

.experienceItem {
  width: 70%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 30px 0;
  justify-content: center;
  height: 120px;
  background-color: var(--general-style-color);
  box-shadow: 0px 0px 5px 0px #3347f2, 0px 0px 5px 0px #3347f2 inset,
    0px 0px 25px 0px #3347f2;
  border: 1px solid white;
  border-radius: 20px;
}

.experienceRow {
  flex: 0 1 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.experienceItemPos {
  color: var(--accenttext, #fff);
  text-align: center;
  font-size: 24px;
  font-family: "Open Sans";
  font-weight: 700;
  letter-spacing: 0.72px;
}

.question {
  width: 90%;
  padding: 10px;
  margin: 10px auto;
  font-family: "Open Sans";
  text-align: justify;
  transition: 500ms ease;
}

.faqCollapse {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.collapseQuestion {
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text, #d9d9d9);
  font-size: 24px;
  margin: 10px auto;
  cursor: pointer;
}
.openedArrow {
  transition: 0.5s ease;
  transform: rotate(180deg);
}
.closedArrow {
  transition: 0.5s ease;
  transform: rotate(0);
}
.collapseAnswer {
  transition: 0.5s;
  height: 0;
  overflow: hidden;
  color: var(--text, #d9d9d9);
  font-size: 20px;
}
.form {
  width: 60%;
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  background-color: var(--general-style-color);
  box-shadow: 0px 0px 5px 0px #3347f2, 0px 0px 15px 0px rgba(0, 0, 0, 0.25),
    0px 0px 15px 0px #3347f2 inset, 0px 0px 25px 0px #3347f2;
  border-radius: 20px;
  border: 1px solid white;
  box-sizing: border-box;
  align-items: center;
  gap: 15px;
}
.formRow {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.formInput {
  width: 80%;
  resize: none;
  min-height: 60px;
  box-sizing: border-box;
  padding: 10px 20px;
  border: none;
  border: 1px solid rgba(177, 177, 177, 0.2);
  border-radius: 10px;
  background: transparent;
  color: #b1b1b1;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin: auto;
  transition: 0.5s;
}
.errorInput {
  border-bottom: 2px solid red;
}

.formInput:focus {
  outline: none;
  border-bottom: 2px solid #999;
}
.disclaimer {
  width: 80%;
  color: rgba(255, 255, 255, 0.5);
  font-size: 10px;
  line-height: 122.414%;
  text-align: left;
}

.footer {
  flex-direction: column;
  justify-content: space-around;
  background-color: rgba(100, 112, 220, 0.1);
  height: 250px;
  margin: 150px 0 0 0;
}

.contacts {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.contact {
  color: #3347f2;
  font-size: 30px;
  transition: 0.3s;
}
.contact:hover {
  transform: scale(1.2);
}

.copyRight {
  font-size: 16px;
  font-weight: 600;
  opacity: 0.4;
}

input,
textarea {
  font-family: "Open Sans";
}
.pcNone {
  display: none;
}

@media screen and (max-width: 1280px) {
  .container {
    width: 650px;
  }

  :root {
    --first-drop: 750px;
  }

  .educationItem {
    flex: 0 1 70%;
    margin: 20px;
  }
  .customSlide {
    height: 400px;
  }
  .lastName {
    font-size: 60px;
    line-height: normal;
  }
  .firstName {
    font-size: 46px;
    line-height: normal;
  }
  .sePosition {
    font-size: 46px;
    line-height: normal;
  }
  .blockHeaderText {
    font-size: 20px;
    margin: 100px 0 50px 0;
  }
  .professionalSkillsLine {
    font-size: 20px;
  }
  .question div {
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {
  :root {
    --first-drop: 360px;
    --block-shadow: 0px 0px 15px 0px var(--color-blue-border-text);
  }
  #root {
    min-width: 375px !important;
  }
  html,
  body {
    max-width: 100%;
    overflow-x: hidden;
  }
  body {
    position: relative;
  }
  .container {
    width: 360px;
  }
  .mobileNone {
    display: none;
  }
  .nameBlock {
    height: 100vh;
  }
  .collapse {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--general-style-color);
    z-index: 10;
    height: 68px;
    transition: 1s ease;
    overflow: hidden;
    transform: translateY(-70px);
  }
  .loaded {
    transform: translateY(0);
  }
  .fullHeight {
    height: 100%;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
  }
  .opened {
    display: flex;
  }
  .closed {
    display: none;
  }
  .header {
    flex-direction: column;
    z-index: 10;
    margin: 0;
    position: absolute;
    top: 70px;
    height: fit-content;
  }
  .burger {
    display: block;
    display: flex;
    align-items: center;
    z-index: 10;
    padding: 10px 0 10px 10px;
  }
  .menu {
    box-sizing: border-box;
    background-color: transparent;
    justify-content: flex-start;
    flex-direction: column;
    height: fit-content;
    align-items: flex-start;
    gap: 10px;
    padding-left: 20px;
  }
  .menuButton {
    font-size: 36px;
    color: #fff;
    height: fit-content;
  }
  .h100 {
    height: 100%;
  }
  .header .contacts {
    gap: 10px;
    padding: 40px 0;
    justify-content: space-around;
  }
  .descriptionBlock {
    font-size: 14px;
    text-align: justify;
  }
  .blockHeaderText {
    font-size: 16px;
    margin: 70px 0 30px 0;
    scroll-margin: 90px;
  }

  .fullName {
    width: 100%;
  }
  .internalNameBlock {
    flex-wrap: wrap;
  }
  .lastName {
    font-size: 64px;
    text-align: left;
  }
  .firstName {
    font-size: 40px;
    text-align: left;
  }
  .sePosition {
    text-align: right;
    width: 100%;
  }
  .educationItem {
    width: 100%;
    flex: 0 1 100%;
    margin: 20px 0;
  }
  .experienceItem {
    width: 100%;
    flex: 0 1 100%;
    margin: 20px 0;
  }
  .collapseQuestion {
    gap: 10px;
  }

  .form {
    width: 100%;
    gap: 20px;
  }
  .disclaimer {
    display: none;
  }
  .pcNone {
    display: flex;
  }

  .customSlide {
    height: 450px;
    align-items: center;
  }
  .slideDescription {
    font-size: 14px;
    flex: 0 1 50%;
    text-align: center;
  }
  .slideTitle {
    font-size: 20px;
    text-align: center;
    justify-content: center;
    flex: 0 1 20%;
  }
  .slideButtons {
    justify-content: center;
  }
  .footer {
    height: 100px;
  }
  .descriptionBlock {
    scroll-margin: 90px;
  }
  .professionalSkillsLine {
    scroll-margin: 70px;
  }
}
