:root {
  --swiper-pagination-bullet-inactive-color: #ffffff;
  --swiper-pagination-bullet-inactive-opacity: 0.6;
  color-scheme: light only;
}

html,
body {
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.swiper-slide {
  box-shadow: 0px 0px 25px 0px var(--color-blue-border-text);
  border-radius: 20px;
}

.swiper-pagination {
  position: relative !important;
  margin-top: 30px;
}

.swiper-pagination-bullet-active {
  background-color: #fff !important;
  width: 30px !important;
  border-radius: 3px !important;
}

.CircularProgressbar {
  width: 200px !important;
  height: 200px;
  border-radius: 100px;
  margin: 30px;
  box-shadow: 0px 0px 25px 0px var(--color-blue-border-text);
}
.CircularProgressbar-text {
  font-size: 16px !important;
  font-weight: 700;
}

/*
  CIRCLES AREA
*/
.circleHeader {
  position: absolute;
  z-index: -1;
  top: -470px;
}
.circleName {
  position: absolute;
  z-index: -1;
  left: 45%;
}
.circleAboutMe1 {
  position: absolute;
  z-index: -1;
  left: 50%;
  transform: translate(-50%, 0);
}
.circleAboutMe2 {
  position: absolute;
  z-index: -1;
  left: 50%;
  transform: translate(-50%, 0);
}
.circleSkillsLeft {
  position: absolute;
  z-index: -1;
  left: -700px;
  transform: translateY(-200px);
}

.circleSkillsRight {
  position: absolute;
  z-index: -1;
  right: -700px;
  transform: translateY(-200px);
}
.circleWork {
  position: absolute;
  z-index: -1;
  left: 50%;
  transform: translate(-50%, -200px);
}

.circleExperienceLeft {
  position: absolute;
  z-index: -1;
  left: -600px;
  transform: translateY(100px);
}
.circleExperienceRight {
  position: absolute;
  z-index: -1;
  right: -600px;
  transform: translateY(100px);
}
.circleForm {
  position: absolute;
  z-index: -1;
  transform: translateY(-100px);
}
.circleLastName {
  position: absolute;
  z-index: -1;
  left: -50px;
  transform: translateY(-30px);
}

@media screen and (max-width: 1280px) {
  .swiper {
    width: 600px;
  }
  .CircularProgressbar {
    width: 150px !important;
    height: 150px;
    border-radius: 75px;
    margin: 25px;
    box-shadow: 0px 0px 15px 0px var(--color-blue-border-text);
  }
  .CircularProgressbar-text {
    font-size: 14px !important;
    font-weight: 50;
  }
}

@media screen and (max-width: 768px) {
  .overflowH {
    overflow-y: hidden !important;
  }
  .swiper {
    width: 320px;
    height: 450px;
  }
  .CircularProgressbar {
    width: 125px !important;
    height: 125px;
    border-radius: 72.5px;
    margin: 15px;
    box-shadow: 0px 0px 8px 0px var(--color-blue-border-text);
  }
  .CircularProgressbar-text {
    font-size: 14px !important;
    font-weight: 25;
  }
  .swiper-pagination {
    margin-bottom: 50px;
  }
  .circleName {
    width: 400px !important;
    height: 250px !important;
    filter: blur(100px) !important;
    left: auto;
    right: 0;
    transform: translate(-100px, -70px) translate3d(0, 0, 0) !important;
  }
  .circleAboutMe2 {
    width: 500px !important;
    height: 500px !important;
    filter: blur(100px) !important;
    left: 0;
    transform: translateY(200px);
  }
  .circleWork {
    width: 300px !important;
    height: 300px !important;
    filter: blur(100px) !important;
  }
  .circleForm {
    width: 500px !important;
    height: 500px !important;
    filter: blur(100px) !important;
  }
  .circleSkillsLeft {
    width: 300px !important;
    height: 300px !important;
    filter: blur(100px) !important;
    left: 0;
    transform: translateY(100px) translate3d(0, 0, 0) !important;
  }
  .circleSkillsRight {
    width: 300px !important;
    height: 300px !important;
    filter: blur(100px) !important;
    right: 0;
    transform: translateY(-300px) translate3d(0, 0, 0) !important;
  }
  .circleExperienceLeft {
    width: 300px !important;
    height: 300px !important;
    filter: blur(100px) !important;
    left: 0;
    transform: translateY(200px) translate3d(0, 0, 0) !important;
  }
  .circleExperienceRight {
    width: 300px !important;
    height: 300px !important;
    filter: blur(100px) !important;
    right: 0;
    transform: translateY(-200px) translate3d(0, 0, 0) !important;
  }
}
@media screen and (max-width: 630px) {
  .circleName {
    transform: translate(30px, -70px) translate3d(0, 0, 0) !important;
  }
}
